<template>
  <div class="bg-imagess bg-cover h-[100vh] flex justify-center items-center">
    <div class="absolute inset-0 bg-black opacity-50 h-[100vh]"></div>
    <div class="container mx-auto">
      <div class="absolute top-0 pt-12 md:pt-12">
        <img
          src="../assets/svgs/GiaVentures-logo.svg"
          alt="Gia Ventures logo"
          class="w-24 md:w-64"
        />
      </div>
      <div class="flex justify-center items-center w-[80%]">
        <p
          class="text-center font-lota-bold absolute left-1/2 transform -translate-x-1/2 text-white text-2xl md:text-6xl"
        >
          Site under construction...
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// <template>
//   <div class="font-lota-normal">
//     <Header @scrollToGetStarted="scrollToGetStarted" />
//     <WhyChooseGia />
//     <ExclusiveAdvertising />
//     <PaidAdvertising />
//     <GetStarted ref="componentB" />
//     <!-- <FAQs /> -->
//     <ContactUs />
//     <Footer />
//   </div>
// </template>

// <script setup>
// import { ref } from "vue";
// import Header from "@/components/giaVentures/Header";
// import WhyChooseGia from "@/components/giaVentures/WhyChooseGia";
// import ExclusiveAdvertising from "@/components/giaVentures/ExclusiveAdvertising.vue";
// import PaidAdvertising from "@/components/giaVentures/PaidAdvertising.vue";
// import GetStarted from "@/components/giaVentures/GetStarted.vue";
// // import FAQs from "@/components/giaVentures/FAQs.vue";
// import ContactUs from "@/components/giaVentures/ContactUs.vue";
// import Footer from "@/components/giaVentures/Footer.vue";

// const componentB = ref(null);

// const scrollToGetStarted = () => {
//   if (componentB.value) {
//     componentB.value.$el.scrollIntoView({ behavior: "smooth" });
//   }
// };
</script>
